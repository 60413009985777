







































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('../post-list.vue'),
    'post-grid': () => import('../post-grid.vue'),
  },
})
export default class PostSearch extends Vue {
  @Inject({}) vm!: SearchViewModel
}
